import config from "config/stores";
import React from "react";
import { Helmet } from "react-helmet-async";
import useFullUrl from "web/core/shop/useFullUrl";

const HomeSeo = () => {
  const fullUrl = useFullUrl("/");
  const baseUrl = fullUrl.replace(/[a-z]+_[a-z]+\/?$/g, '');
  const storesEndUrls = Object.keys(config).map(key => key.replace(/(?:\w+)_([a-z]+)_([a-z]+)/, (_, country, locale) => JSON.stringify(({locale, country, url: `${baseUrl}${locale}_${country}/`})))).map(JSON.parse);

  return (
    <Helmet>
      <link rel="canonical" href={fullUrl} />
      {storesEndUrls.map(({locale, url}) => (
          <link
            key={url}
            rel="alternate"
            hreflang={locale}
            href={url}
          />
        )
      )}
    
    </Helmet>
  );
};

export default HomeSeo;
