import React from "react";
import compose from "recompose/compose";
import { withRouter } from "react-router";
import withTrackPage from "theme/modules/Analytics/withTrackPage";
import classNames from "classnames";
import Loadable from "theme/components/helpers/Loadable";
import { SuccessAlert } from "theme/components/molecules/Alert";
import { H1 } from "theme/components/atoms/Typography/Heading";
import HomeSeo from "theme/modules/Home/HomeSeo";
import Olapic from "theme/modules/Olapic";
import EnhanceHome from "./EnhanceHome";
import PageQuery from "./PageQuery.gql";
import WysiwygV2 from "theme/modules/WysiwygV2";
import useFullUrl from "web/core/shop/useFullUrl";
import withHeaderAnimation from "web/core/tools/withHeaderAnimation";
import withPageCmsHeroBanner, { HERO_BANNER_THEME_MAP } from "../CmsPage/withPageCmsHeroBanner";
import CmsBlock from "theme/modules/CmsBlock/CmsBlock";


const Home = (props) => {
  const homeUrl = useFullUrl();
  const { cms: { kpl_header_theme }, heroBanner } = props;

  const orgMapping = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Kaporal",
    url: homeUrl,
    logo: "https://www.kaporal.com/skin/frontend/rwd/kaporalv2/images/logo-kaporal.svg",
    // todo: don't forget to turn into a dynamic url when logo is configurable (   const logoUrl = useFullUrl(configurableLogoUrl); )
    sameAs: [
      "https://www.facebook.com/kaporal5jeans",
      "https://twitter.com/Kaporal",
      "https://www.instagram.com/kaporalofficiel/",
      "https://www.youtube.com/user/kaporal",
      "https://www.pinterest.fr/kaporaljeans/",
    ],
  };

  const pageClasses = classNames('page__home', {
    'cms-page': true,
    'hero_banner': !!kpl_header_theme && heroBanner,
  });

  return (
    <>
      <div className={ pageClasses }>
        { heroBanner
          &&
          <div kha={HERO_BANNER_THEME_MAP[kpl_header_theme]}>
            <CmsBlock cmsBlock={heroBanner.data} />
          </div>
        }
        <meta itemProp="Type" content="Organization" />
        <div itemScope itemType="http://schema.org/WebPage">
          <div className="container">
            {props.location.state && props.location.state.successMessage && (
              <SuccessAlert>{props.location.state.successMessage}</SuccessAlert>
            )}
          </div>
          <HomeSeo />
          <div className="page-content">
            <div className="container">
              {props.cms.content_heading && (
                <H1 itemProp="headline">{props.cms.content_heading}</H1>
              )}
              <article itemProp="mainContentOfPage">
                <WysiwygV2
                  containerClass="home"
                  content={props.cms.contentWysiwyg}
                />
              </article>
            </div>
          </div>
          <Olapic/>
        </div>
      </div>
      <script type="application/ld+json" dangerouslySetInnerHTML={{
        __html: JSON.stringify(orgMapping)
      }} />
    </>
  );
};

export default compose(
  EnhanceHome({
    PageQuery,
  }),
  withRouter,
  Loadable((props) => !props.loading),
  withTrackPage("Home"),
  withHeaderAnimation(),
  withPageCmsHeroBanner()
)(Home);
