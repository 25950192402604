import { compose } from "recompose";
import { graphql } from "react-apollo";

const EnhanceCmsPage = ({ PageQuery }) =>
  compose(
    graphql(PageQuery, {
      options: (props) => ({
        variables: {
          identifiers: ["home"],
        },
      }),
      props: ({ data }) => ({
        loading: data.loading,
        cms:
          !data.loading &&
          data.cmsPageList &&
          data.cmsPageList.length > 0 &&
          data.cmsPageList[0],
      }),
    })
  );

export default EnhanceCmsPage;
