import { graphql } from "react-apollo";
import CmsBlockQuery from "theme/modules/CmsBlock/CmsBlockQuery.gql";

export const HERO_BANNER_PREFIX = 'hero_banner_';

// same as src/app/code/local/Kaporalmrs/Cms/Model/HeaderTheme.php
export const HERO_BANNER_THEME_MAP = {
  1: 'black',
  2: 'white'
};

/**
 * Query hero banner and add it to props.
 */
export default () =>
  graphql(CmsBlockQuery, {
    name: 'heroBanner',
    options: ({ cms: { identifier }}) => {
      return {
        variables: {
          identifiers: [`${HERO_BANNER_PREFIX}${identifier}`]
        },
      }
    },
    props: ({ heroBanner, ownProps : {cms: {identifier}} }) => {
      return {
        heroBanner: {
          loading: heroBanner.loading,
          data:
            !heroBanner.loading &&
            heroBanner.cmsBlockList &&
            heroBanner.cmsBlockList.find(
              (block) => block && block.identifier === `${HERO_BANNER_PREFIX}${identifier}`
            ),
        },
      }
    },
  });